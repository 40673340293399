const hostAssurance = 'https://intransit-dev.altomovup.com';
const hostAlliance = 'https://custos-developer.altoalliance.com';

declare const localStorage:any;

export const environment = {
  GOOGLE_API_KEY:'AIzaSyCofjsYXV1QPETBV4bKJgyWiZrCAV2U1Uo',
  production: true,
  static: '', 
  urlAlertRules: hostAssurance + '/api/v1/controltower/conf',
  urlMsOverview: hostAssurance + '/api/v1/overview/',
  urlPlaces: hostAssurance + '/api/v1/places',
  urlTrips: hostAssurance + '/api/v1/trips',
  urlUserConfig: hostAssurance + '/api/v1/user',

  eventSocketUrl: hostAssurance,
  get eventSocketToken(){
    const u = JSON.parse(localStorage.getItem('User'));
    if(u != undefined)
      return u.simple_token
    return ''
  },
  get urlIframeShipments(){
    const userLogueado = JSON.parse(localStorage.getItem('User'));
    if(userLogueado != undefined)
      return hostAssurance + '/api/v1/shiny/trips/?account_id=' + userLogueado['account']['id']
    return null;
  },
  get urlIframeResources(){
    const userLogueado = JSON.parse(localStorage.getItem('User'));
    if(userLogueado != undefined)
      return hostAssurance + '/api/v1/shiny/resources/?account_id=' + userLogueado['account']['id']
      return null;
  },
  get urlIframeInTransit(){
    const userLogueado = JSON.parse(localStorage.getItem('User'));
    if(userLogueado != undefined)
      return hostAssurance + '/api/v1/shiny/intransit/?account_id=' + userLogueado['account']['id']
      return null;
  },
  get urlIframeLoads(){
    const userLogueado = JSON.parse(localStorage.getItem('User'));
    if(userLogueado != undefined)
      return hostAssurance + '/api/v1/shiny/loads/?account_id=' + userLogueado['account']['id']
    return null;
  },
  urlIframeTemperature: hostAssurance + '/api/v1/shiny/temperature/?',
  urlMenu: hostAssurance + '/api/v1/user',
  urlTrafficService: hostAssurance + '/traffic',
  urlAuthenticate: hostAlliance + ':444/authenticate',
  urlApi: hostAssurance + '/auth',
  urlCallCenter: hostAssurance + '/api/v1/callcenter',
  urlStats: hostAssurance + '/api/v1/stats',
  ulrTripsFile: hostAssurance + '/track-interop/excel-trips',
  urlOsrm: hostAssurance + '/osrm/api/v1',
  urlDrivers: hostAssurance + '/api/v1/drivers',
  urlCarrier:hostAssurance +'/api/v1/carriers',
  urlTripsLogistic: hostAssurance + '/api/v1/logistic',
  urlEvents: hostAssurance + '/api/v1/general-events',
  urlTransport: hostAssurance +'/api/v1/transport',
  urlTripsPlanning: hostAssurance + '/api/v1/trip-planning',
  urlLoads: hostAssurance + '/api/v1/loads',
  urlShipments: hostAssurance + '/api/v1/shipments',
  urlAuthPython: hostAssurance+ '/api/v1/auth',
  streamEvent:hostAssurance +'/event-stream/api/v1',
  urlCallCenterOp: hostAssurance+ '/api/v1/controltower-overview',
  urlRoutes: hostAssurance+ '/api/v1/routes',
  urlFiles: hostAssurance+ '/api/v2/files',
  urlSignalsMap: hostAssurance+ '/api/v1/signals-map',
  urlCards: hostAssurance+ '/api/v1/cards',
  urlCost: hostAssurance+ '/api/v1/estimates',
  urlEventsInterop: hostAssurance + '/events-interop/api/v1',
  urlUtils: hostAssurance + '/utils/api/v1',
  urlDiscovery : hostAssurance + '/api/v1/discovery',
  urlTransformer: hostAssurance + '/api/v1/transformer',
  urlInsight : hostAssurance + '/api/v1/insight',
  urlCluster : hostAssurance + '/api/v1/data-cluster',
  urlTasks: hostAssurance + '/api/v2/tasks',
  urlNotifications: hostAssurance + '/api/v2/notifications',
  injector:{
    demoAccount: {
      account_id : 18046,
      name : "Demo"
    },
    demoCarrier:{carrier_id: 18060, name: "Carrier One",carrier_code: "c-one-18060", active: true},
    demoAdmin:1903936,
    demoToken:'ZGVtbzpDc3hkWWU2ajJCT1J5U1A=',
    demoSendSignal: hostAssurance+'/gpssignal/api/v1/data/demo-cl',
    tripList: hostAssurance+'/api/v1/trips/list/account/$accountId/',
    tripGet:  hostAssurance+'/api/v1/trips/$tripCode/account/$accountId/',
    tripCreate: hostAssurance+'/api/v1/trips/account/$accountId/',
    tripSignals: hostAssurance+'/api/v1/stats/map/findRoute?tripId=$tripId&accountId=$accountId',
    tripAction: hostAssurance+'/api/v1/trips/trip/$tripId/cycle_update?action=$action&user_id=$userId&account_id=$accountId&date=$date',
    tripStart: hostAssurance+'/api/v1/trips/trip/$tripId/cycle_update?action=start&user_id=$userId&account_id=$accountId&date=$date',
    tripStop: hostAssurance+'/api/v1/trips/trip/$tripId/cycle_update?action=close&user_id=$userId&account_id=$accountId&date=$date',
    tripMonitor: hostAssurance+'/main/trips/detail/$tripId',

    placeGet: hostAssurance+'/api/v1/places/place/$placeId/$accountId',
    placeCreate: hostAssurance+'/api/v1/places/place',
    driverGet: hostAssurance+'/api/v1/drivers/get_driver_basic_info/$driverId/account/$accountId',
    personGet:hostAssurance+'/api/v1/user/person?person_id=$personId&account_id=$accountId',
    personCreate: hostAssurance+'/api/v1/user/person',
    carrierUpdate: hostAssurance+'/api/v1/drivers/update_carriers/$driverId/account/$accountId',
    transportCreate : hostAssurance+'/api/v1/transport/add_transport',
    transportGet: hostAssurance+'/api/v1/transport/get_transport_details/$transportId/account/$accountId',

    templateCreate: hostAssurance+'/signal-injector/api/v1/template',
    templateList: hostAssurance+'/signal-injector/api/v1/template/list',
    tripTurn: hostAssurance+'/signal-injector/api/v1/trip/$accountId/$tripId/$turn',
    sendSignal: hostAssurance+'/signal-injector/api/v1/signal'
   
  }
};
