<div class="container  content-header__wrapper inside-tabs no-gutters" style="margin: auto; text-align: justify; overflow-y: auto;
height: 100%">
  <!---->
  <!---->
  <div>
    <div class="row">
      <div>
        <header class="content-header">
          <p class="content-header__name text-primary fs-34 "> Políticas de Privacidad</p>
          <p class="content-header__info fs-13  m-0">Las siguientes Políticas de Privacidad fueron actualizadas el 6 de
            septiembre del 2023.</p>
        </header>
      </div>
    </div>
    <div class="row">
      <div> <br>
        <p class="form-control-paragraphs fs-22 ">Nuestra política</p>
        <p class="form-control-paragraphs fs-15 ">
          Bienvenido al sitio web (el <b>"Sitio web"</b>) de Alto USA, LLC ("Alto", "Compañía", "nosotros",
          "nosotros" y
          / o "nuestro"). Este sitio web es operado por Alto y ha sido creado para proporcionar información sobre
          nuestra empresa y nuestras soluciones de mitigación de riesgos, aplicaciones móviles y servicios relacionados
          (junto con el sitio web, los "Servicios") a nuestros visitantes del Servicio ("usted", "su" ) Alto se
          compromete
          a mantener sólidas protecciones de privacidad para sus usuarios. Nuestra Política de privacidad está diseñada
          para ayudarlo a comprender cómo recopilamos, usamos y salvaguardar la información que nos proporciona,
          incluidos
          los datos de identificación personal ("<b>Datos personales</b>") y otra información que se recopila de los
          visitantes
          del Sitio web y los Servicios, y para ayudar a usted a tomar decisiones informadas al usar nuestros Servicios.
          Al aceptar nuestra Política de privacidad y los Términos de uso de Alto Alliance, Alto Movup, Alto Checks y
          Alto Inmune,
          acepta su recopilación, almacenamiento, uso y divulgación de su información personal tal como se describe en
          esta
          Política de privacidad. Esta Política de privacidad se incorpora por referencia en los <b>Términos de uso</b>.
          Cualquier término en
          mayúscula no definido en este documento se define en los Términos de uso.
        </p>
        <p class="form-control-paragraphs fs-22 ">Información que recopilamos</p>
        <p class="form-control-paragraphs fs-15 ">Cuando interactúa con nosotros a través de los Servicios, podemos
          recopilar Datos personales y otra información de usted, como se describe a continuación: </p>
        <ul>
          <li>Nombre y apellido</li>
          <li>Dirección de correo electrónico</li>
          <li>Dirección física</li>
          <li>Número de teléfono</li>
          <li>Número de seguridad social</li>
          <li>Número de licencia de conducir</li>
          <li>Edad</li>
          <li>Raza / etnia género</li>
          <li>Fecha de nacimiento</li>
          <li>Fotografías</li>
          <li>Información de geolocalización</li>
        </ul>
        <p class="form-control-paragraphs fs-15 "><b>Datos personales que usted proporciona a través de los
            Servicios:</b> recopilamos datos personales cuando usted proporciona voluntariamente dicha información,
          como cuando nos contacta con preguntas, responde a una de nuestras encuestas, se registra para acceder
          a los Servicios, utiliza ciertos Servicios y de otra manera comunicarse o enviarnos información o contenido.
          Los datos personales se utilizan para ayudarnos a comunicarnos con usted. Por ejemplo, usamos Datos personales
          para contactar a usuarios para responder preguntas, solicitar retroalimentación de los usuarios y cuando
          se proporciona soporte técnico. Siempre que Alto recopila datos personales, hacemos un esfuerzo para
          proporcionar un enlace a esta Política de privacidad.

        </p>
        <p class="form-control-paragraphs fs-15 "><b>Al proporcionarnos voluntariamente datos personales, usted acepta
            su
            uso de acuerdo con esta Política de privacidad. Si proporciona datos personales a los servicios, reconoce y
            acepta
            que dichos datos personales pueden transferirse desde su ubicación actual a las oficinas y servidores de
            Alto y los
            terceros autorizados a los que se hace referencia en el presente en país.
          </b></p>
        <p class="form-control-paragraphs fs-15 "><b>Otra información</b></p>
        <p class="form-control-paragraphs fs-15 "><b>Datos no identificables:</b> Cuando interactúa con Alto a través de
          los
          Servicios, recibimos y almacenamos cierta información personal no identificable, como datos de uso anónimos,
          información demográfica general, páginas de referencia / salida y URL, tipos de plataforma, preferencias que
          envía y preferencias que se generan en función de los datos que envíe y el número de clics. Dicha información,
          que se recopila de forma pasiva utilizando diversas tecnologías, se utiliza para ayudarnos a mejorar los
          Servicios
          y personalizar la experiencia del usuario, y actualmente no se puede utilizar para identificarlo
          específicamente.
          Alto puede almacenar dicha información en sí o tal información puede incluirse en bases de datos de propiedad
          y
          mantenidas por afiliados, agentes o proveedores de servicios de Alto. Los Servicios pueden usar dicha
          información y
          agruparla con otra información para rastrear, por ejemplo, el número total de visitantes de nuestro sitio web,
          el
          número de visitantes de cada página de nuestro sitio web y los nombres de dominio de los proveedores de
          servicios
          de Internet de nuestros visitantes. Es importante tener en cuenta que no hay datos personales disponibles o
          utilizados
          en este proceso.
        </p>
        <p class="form-control-paragraphs fs-15 ">Al operar los Servicios, podemos usar una tecnología llamada
          "cookies".
          Una cookie es una información que se almacena en la computadora cuando ingresa al sitio que aloja nuestros
          Servicios.
          Nuestras cookies ayudan a proporcionar una funcionalidad adicional a los servicios y nos ayudan a analizar el
          uso de
          los Servicios con mayor precisión. Por ejemplo, nuestro sitio web puede establecer una cookie en su navegador
          que le
          permite acceder a los servicios sin necesidad de recordar e ingresar una contraseña más de una vez durante una
          visita
          al sitio web. En todos los casos en los que usamos cookies, no recopilaremos datos personales, excepto con su
          permiso.
          En la mayoría de los navegadores web, encontrará una sección de "ayuda" en la barra de herramientas. Consulte
          esta
          sección para obtener información sobre cómo recibir notificaciones cuando recibe una nueva cookie y cómo
          desactivarlas.
          Recomendamos que deje las cookies activadas porque le permiten aprovechar algunas de las características del
          Servicio.
        </p>
        <p class="form-control-paragraphs fs-15 "><b>Datos personales agregados:</b> En un esfuerzo continuo por
          comprender y
          servir mejor a los usuarios de los Servicios, Alto a menudo realiza investigaciones sobre la demografía, los
          intereses y
          el comportamiento de sus clientes en función de los Datos personales y otra información que nos proporciona.
          Esta
          investigación puede compilarse y analizarse de forma agregada. Esta información agregada se utiliza para
          rastrear
          tendencias y analizar patrones de uso en el sitio web, y no lo identifica a usted personalmente. Alto puede
          compartir
          esta información agregada con sus afiliados, agentes y socios comerciales. Alto también puede divulgar
          estadísticas
          de usuario agregadas para describir nuestros servicios a socios comerciales actuales y futuros, y a otros
          terceros
          para otros fines legales.
        </p>
        <p class="form-control-paragraphs fs-15 "><b>Información de ubicación:</b> Nuestros Servicios pueden recopilar y
          usar
          su información de ubicación (por ejemplo, mediante el uso del GPS en su dispositivo móvil) para proporcionar
          cierta
          funcionalidad de nuestros Servicios. Si elige habilitar nuestras funciones de ubicación, su información de
          ubicación
          puede mostrarse públicamente en los Servicios. También podemos usar su información de ubicación de manera
          agregada,
          como se describe anteriormente en la sección "Datos personales agregados".
        </p>
        <p class="form-control-paragraphs fs-15 "><b>Seguimiento en línea:</b>
          No rastrear ("DNT") es una configuración del navegador web que solicita que una aplicación web o sitio web
          deshabilite
          el seguimiento de un usuario individual. No existe una regulación que requiera que las compañías cambien sus
          prácticas
          en respuesta a una señal DNT. En este momento, Alto no responde a una señal DNT de su navegador web. Para
          obtener más
          información sobre DNT, visite www.allaboutdnt.com.
        </p>
        <p class="form-control-paragraphs fs-15 "><b>Análisis:</b> Podemos permitir que los proveedores de servicios
          externos
          utilicen cookies o tecnologías similares para recopilar información sobre sus actividades de navegación a lo
          largo
          del tiempo y en diferentes sitios web después de su uso de los Servicios. Por ejemplo, utilizamos un servicio
          de
          análisis patentado similar a Google Analytics, que utiliza cookies para ayudarnos a analizar cómo los usuarios
          usan el
          sitio web y mejorar su experiencia cuando utiliza los Servicios. No implementamos ninguna función publicitaria
          con
          nuestro servicio de análisis.
        </p>
        <p class="form-control-paragraphs fs-22 ">Nuestro uso de sus datos personales y otra información</p>
        <p class="form-control-paragraphs fs-15 ">Alto utiliza los Datos personales que usted proporciona de manera
          coherente
          con esta Política de privacidad. Si proporciona datos personales, podemos usar los datos personales en
          relación con
          el motivo por el que se proporcionaron. Por ejemplo, si nos contacta por correo electrónico, utilizaremos los
          Datos
          personales que nos proporcione para responderle. O, si proporciona Datos personales para obtener acceso a los
          Servicios,
          utilizaremos sus Datos personales para proporcionarle acceso a dichos servicios y para supervisar su uso. Alto
          y sus subsidiarias y afiliadas (las "Compañías relacionadas") también pueden usar sus Datos personales y otra
          información personal no identificable recopilada a través de los Servicios para ayudarnos a mejorar el
          contenido y la funcionalidad de los Servicios, comprender mejor a nuestros usuarios y mejorar los servicios.
          Alto y sus afiliados pueden usar esta información para contactarlo en el futuro para informarle sobre los
          servicios que creemos que serán de su interés. También puede indicar que no desea recibir nuestras
          comunicaciones de marketing en la sección "Configuración" del sitio web. Además, si en algún momento no desea
          recibir ninguna comunicación de marketing futura o si desea que su nombre sea eliminado de nuestras listas de
          correo, contáctenos como se indica a continuación. Sin embargo, tenga en cuenta que, a pesar de sus
          preferencias de marketing, podemos seguir enviándole correos electrónicos administrativos.

        </p>
        <p class="form-control-paragraphs fs-15">Si Alto tiene la intención de utilizar los Datos personales de
          cualquier manera que no sea coherente con esta Política de privacidad, se le informará de dicho uso anticipado
          antes o en el momento en que se recopilan los Datos personales.
        </p>
        <p class="form-control-paragraphs fs-22 ">Nuestra divulgación de sus datos personales y otra información.</p>

        <p class="form-control-paragraphs fs-15 ">Alto no está en el negocio de vender, comerciar, alquilar o de otra
          manera compartir con fines de marketing sus Datos personales sin su consentimiento. Consideramos que esta
          información es una parte vital de nuestra relación con usted. Sin embargo, existen ciertas circunstancias en
          las que podemos compartir sus Datos personales con terceros sin previo aviso, como se establece a
          continuación:
        </p>
        <p class="form-control-paragraphs fs-15 "><b>Transferencias comerciales:</b> a medida que desarrollamos nuestro
          negocio, podríamos vender o comprar negocios o activos. En el caso de una venta corporativa, fusión,
          reorganización, disolución o evento similar, los Datos personales pueden ser parte de los activos
          transferidos.
        </p>
        <p class="form-control-paragraphs fs-15 "><b>Compañías relacionadas:</b> También podemos compartir sus datos
          personales con nuestras compañías relacionadas para fines consistentes con esta Política de privacidad.

        </p>
        <p class="form-control-paragraphs fs-15 "><b>Agentes, consultores y terceros relacionados:</b> Alto, como muchas
          empresas, a veces contrata a otras empresas para realizar ciertas funciones relacionadas con el negocio.
          Ejemplos de tales funciones incluyen proveedores que realizan servicios para Alto, envían información por
          correo, mantienen bases de datos y procesan pagos. Cuando empleamos a otra entidad para realizar una función
          de esta naturaleza, solo les proporcionamos la información que necesitan para realizar su función específica.
        </p>
        <p class="form-control-paragraphs fs-15 "><b>Requisitos legales:</b> Alto puede divulgar sus Datos personales si
          así lo exige la ley o si cree de buena fe que dicha acción es necesaria para (i) cumplir con una obligación
          legal, (ii) proteger y defender los derechos o la propiedad de Alto, (iii) actuar en circunstancias urgentes
          para proteger la seguridad personal de los usuarios de los Servicios o del público, o (iv) proteger contra la
          responsabilidad legal.
        </p>
        <p class="form-control-paragraphs fs-22 ">Tus opciones</p>
        <p class="form-control-paragraphs fs-15 ">Puede visitar el sitio web sin proporcionar ningún dato personal. Si
          elige no proporcionar ningún dato personal, es posible que no pueda usar ciertos Servicios. También puede
          modificar o actualizar los Datos personales que recopilamos accediendo a su información de "Perfil" en nuestro
          sitio web. La modificación de sus Datos personales puede restringir su acceso a ciertos Servicios.
        </p>
        <ol type="1">
          <li>Correo electrónico: a aquellos que deseen optar por no recibir correos electrónicos, se les eliminará su
            dirección dentro de los 1-10 días posteriores a la recepción de su solicitud de exclusión o de darse de baja
            en los canales definidos en “Como contactarnos”. Si tiene varias direcciones de correo electrónico, deberá
            optar por no participar en cada dirección para que se elimine de nuestra base de datos activa.
          </li>
          <li>Información de ubicación: puede tener la capacidad de activar y desactivar los servicios basados en la
            ubicación ajustando la configuración de su navegador de Internet o dispositivo móvil o, para algunos
            dispositivos, revocando el permiso para que nuestras aplicaciones móviles accedan a su información de
            ubicación.
          </li>
          <li>Cookies y balizas web: su navegador puede ofrecer la capacidad de bloquear o eliminar cookies de su
            dispositivo.
          </li>
        </ol>
        <p class="form-control-paragraphs fs-22 ">Exclusiones</p>
        <p class="form-control-paragraphs fs-15 ">Esta Política de privacidad no se aplica a los Datos personales
          recopilados por Alto que no sean los Datos personales recopilados a través de los Servicios. Esta Política de
          privacidad no se aplicará a ninguna información no solicitada que proporcione a Alto a través de los Servicios
          o por cualquier otro medio. Esto incluye, pero no se limita a, información publicada en áreas públicas de los
          Servicios, como foros, ideas para nuevos productos o servicios, o modificaciones a productos o servicios
          existentes, y otras presentaciones no solicitadas (colectivamente, "Información no solicitada") Toda la
          información no solicitada se considerará no confidencial y Alto podrá reproducir, usar, divulgar y distribuir
          dicha información no solicitada a otros sin limitación ni atribución.
        </p>
        <p class="form-control-paragraphs fs-22 ">Enlaces a otros sitios web</p>
        <p class="form-control-paragraphs fs-15 ">Esta Política de privacidad se aplica solo a los Servicios. Los
          Servicios pueden contener enlaces a otros sitios web no operados o controlados por Alto (los "Sitios de
          terceros"). Sin embargo, no somos responsables de las prácticas de privacidad empleadas por esos Sitios de
          terceros ni de la información o el contenido que contienen. Nuestra Política de privacidad y las políticas y
          procedimientos descritos en este documento no se aplican a los Sitios de terceros. Los enlaces de los
          Servicios no implican que Alto respalde o haya revisado los Sitios de terceros. Sugerimos contactar esos
          sitios directamente para obtener información sobre sus políticas de privacidad. Estos son algunos ejemplos de
          contenido y servicios de terceros disponibles a través de nuestro Sitio:
        </p>
        <ol type="1">
          <li>Redes sociales: podemos facilitar el acceso fácil a sitios de terceros y servicios en línea, como redes
            sociales y otros servicios que alojan contenido generado por los usuarios, como LinkedIn y Twitter.
          </li>
        </ol>
        <p class="form-control-paragraphs fs-22 ">Niños</p>
        <p class="form-control-paragraphs fs-15 ">Alto no recopila a sabiendas Datos personales de niños menores de 13
          años. Si usted es menor de 13 años, no envíe datos personales a través de los Servicios. Alentamos a los
          padres y tutores legales a monitorear el uso de Internet de sus hijos y a ayudar a hacer cumplir nuestra
          Política de privacidad, instruyendo a sus hijos a que nunca proporcionen Datos personales en los Servicios sin
          su permiso. Si tiene motivos para creer que un niño menor de 13 años ha proporcionado Datos personales a Alto
          a través de los Servicios, comuníquese con nosotros y nos esforzaremos por eliminar esa información de
          nuestras bases de datos.
        </p>
        <p class="form-control-paragraphs fs-22 ">Seguridad
        </p>
        <p class="form-control-paragraphs fs-15 ">Alto esta certificada en <a href="https://www.iso.org/standard/27001"
            target="_blank">ISO/IEC 27001</a> por tanto tiene implementado un gobierno y controles de seguridad de la
          información con el fin de mantener la integridad, confidencialidad y disponibilidad de los datos. Por tal
          motivo Alto toma medidas razonables para proteger los Datos personales proporcionados a través de los
          Servicios contra pérdida, mal uso y acceso no autorizado, divulgación, alteración o destrucción. Implementamos
          ciertas medidas tecnológicas de seguridad que incluyen cifrado, firewalls y tecnología de capa de conexión
          segura, sin embargo, estas medidas no garantizan que no se accederá, divulgará, alterará o destruirá su
          información por incumplimiento de dichos firewalls y software de servidor seguro. Ninguna transmisión por
          Internet o correo electrónico es completamente segura o libre de errores. En particular, el correo electrónico
          enviado desde los Servicios puede no ser seguro. Por lo tanto, debe tener especial cuidado al decidir qué
          información nos envía por correo electrónico. Tenga esto en cuenta al divulgar cualquier información personal
          a Alto a través de Internet. Al utilizar nuestros Servicios, usted reconoce que comprende y acepta asumir
          estos riesgos.

        </p>
        <p class="form-control-paragraphs fs-22 ">Acceda desde su teléfono móvil</p>
        <p class="form-control-paragraphs fs-15 ">Recopilamos y usamos información sobre usted de la misma manera y con
          los mismos fines si accede a los Servicios desde su teléfono móvil, como lo hacemos cuando utiliza nuestro
          sitio web.

        </p>
        <p class="form-control-paragraphs fs-22 ">Otros términos y condiciones</p>
        <p class="form-control-paragraphs fs-15 ">Su acceso y uso de los Servicios está sujeto a los Términos de
          uso.</p>
        <p class="form-control-paragraphs fs-22 ">Cambios en la política de privacidad de la empresa</p>
        <p class="form-control-paragraphs fs-15 ">Los Servicios y nuestro negocio pueden cambiar de vez en cuando. Como
          resultado, a veces puede ser necesario que Alto realice cambios a esta Política de privacidad. Le
          notificaremos sobre cualquier cambio significativo en nuestra Política de privacidad enviando un aviso a la
          dirección de correo electrónico principal especificada en su cuenta o colocando un aviso destacado en nuestro
          sitio web. Los cambios significativos entrarán en vigencia 30 días después de dicha notificación. Alto se
          reserva el derecho de hacer cambios o aclaraciones no materiales a esta Política de privacidad en cualquier
          momento y de vez en cuando sin previo aviso. Los cambios o aclaraciones no materiales entrarán en vigencia de
          inmediato. Debe revisar esta Política de privacidad y el Sitio web periódicamente para obtener
          actualizaciones, y especialmente antes de proporcionar Datos personales. Esta Política de privacidad se
          actualizó por última vez en la fecha indicada anteriormente. Su uso continuado de los Servicios después de
          cualquier cambio o revisión de esta Política de privacidad indicará que está de acuerdo con los términos de
          dicha Política de privacidad revisada.
        </p>
        <p class="form-control-paragraphs fs-22 ">Ley que rige </p>
        <p class="form-control-paragraphs fs-15 ">Este aviso y nuestras prácticas de privacidad estarán sujetas
          exclusivamente a las leyes del país. Al utilizar nuestros Servicios, usted presenta irrevocablemente a la
          jurisdicción exclusiva de los tribunales competentes cualquier reclamo o asunto que surja en relación con los
          Servicios.

        </p>
        <p class="form-control-paragraphs fs-22 ">Sus derechos de datos personales</p>
        <p class="form-control-paragraphs fs-15 ">Es posible que conozca una ley de la Unión Europea llamada Reglamento
          General de Protección de Datos o "GDPR" que otorga ciertos derechos a las personas en relación con sus datos
          personales. En consecuencia, hemos implementado una transparencia adicional para ayudar a los usuarios a
          aprovechar esos derechos. Según esté disponible y salvo lo limitado por la ley aplicable, las personas tienen
          los derechos que se describen a continuación:


        </p>
        <p class="form-control-paragraphs fs-15 ">Si desea <b>acceder</b> a la información personal que Alto
          recopila,
          puede hacerlo en cualquier momento poniéndose en contacto con nosotros utilizando los datos de contacto
          proporcionados en el apartado "Cómo contactarnos" a continuación.</p>
        <p class="form-control-paragraphs fs-15 "> Los titulares de cuentas de Alto pueden <b>revisar, actualizar,
            corregir o eliminar</b> la información personal en su perfil de registro iniciando sesión en su cuenta. Los
          titulares de cuentas de Alto también pueden comunicarse con nosotros para cumplir con lo anterior o si tienen
          solicitudes o preguntas adicionales.

        </p>
        <p class="form-control-paragraphs fs-15 ">
          Si es residente del Espacio Económico Europeo ("EEE"), puede oponerse al <b>procesamiento</b> de su
          información
          personal, solicitarnos que <b>restrinjamos el procesamiento</b> de su información personal o
          <b>solicitar la
            portabilidad</b> de su información personal donde sea técnicamente posible. Nuevamente, puede
          ejercer estos
          derechos comunicándose con nosotros usando los detalles de contacto a continuación.
        </p>
        <p class="form-control-paragraphs fs-15 ">
          Del mismo modo, si usted es residente del EEE, si hemos recopilado y procesado su información personal
          con su consentimiento, puede <b>retirar su consentimiento</b> en cualquier momento. Retirar su
          consentimiento
          no afectará la legalidad de cualquier procesamiento que hayamos realizado antes de su retiro, ni
          afectará el procesamiento de su información personal realizada en base a motivos legales de
          procesamiento que no sean el consentimiento.
        </p>
        <p class="form-control-paragraphs fs-15 ">
          Tiene derecho a presentar una queja ante una <b>autoridad de protección de datos</b> sobre nuestra
          recopilación
          y uso de su información personal. Los datos de contacto de las autoridades de protección de datos en el
          EEE, Suiza y ciertos países no europeos (incluidos EE. UU. Y Canadá) están disponibles <a
            href="https://ec.europa.eu/justice/article-29/structure/data-protection-authorities/index_en.htm" target="_blank">aquí</a>.).

        </p>
        <p class="form-control-paragraphs fs-15 ">Respondemos a todas las solicitudes que recibimos de personas que
          desean ejercer sus derechos de protección de datos de acuerdo con las leyes de protección de datos
          aplicables.</p>
        <p class="form-control-paragraphs fs-15 ">Si es residente de EE. UU., como la Ley de Privacidad del Consumidor
          de California (CCPA), la Ley de Protección de Datos del Consumidor de Virginia (VCDPA), la Ley de Privacidad
          de Colorado (CPA), la Ley de Privacidad del Consumidor de Utah (UCPA) y Ley de Privacidad de Datos de
          Connecticut (CTDPA) requieren divulgaciones específicas para los residentes del estado y/o estados donde
          aplique, en la presente política se detalla la información que se recopila (“Información que recopilamos”), su
          uso (“Nuestro uso de sus datos personales y otra información”) y divulgación (“Nuestra divulgación de sus
          datos personales y otra información”). Si tiene preguntas o solicitudes relacionadas con sus derechos en
          virtud de la CCPA, VCDPA, CPA, UCPA o CTDPA puede comunicarse con Alto. En caso de que no esté de acuerdo con
          la decisión sobre su solicitud, puede pedirle a Alto que la reconsidere respondiendo en la solicitud realizada
          por nuestros canales “Cómo contactarnos”.</p>
        <p class="form-control-paragraphs fs-22 ">Retención de datos</p>
        <p class="form-control-paragraphs fs-15 ">Conservamos sus datos personales solo según lo exija la ley o el
          tiempo que sea necesario para fines comerciales legítimos y esenciales, como cumplir un pedido, tomar
          decisiones comerciales basadas en datos sobre nuevas características y ofertas, cumplir con nuestras
          obligaciones legales y resolver disputas. Conservamos algunos de sus datos personales mientras sea titular de
          una cuenta.
        </p>
        <p class="form-control-paragraphs fs-15 ">Si lo solicita, eliminaremos o lo mantendremos de manera anónima sus
          datos personales para que ya no lo identifiquen, a menos que, legalmente, se nos permita o requiera mantener
          ciertos datos personales, incluidas situaciones como las siguientes:
        </p>
        <p class="form-control-paragraphs fs-15 ">Si hay un problema no resuelto relacionado con su cuenta, como un
          crédito pendiente en su cuenta o un reclamo o disputa sin resolver, conservaremos los datos personales
          necesarios hasta que se resuelva el problema;
        </p>
        <p class="form-control-paragraphs fs-15 ">Cuando se nos requiera conservar los datos personales para nuestras
          obligaciones legales, fiscales, de auditoría y contables, conservaremos los datos personales necesarios
          durante el período requerido por la ley aplicable; y/o,
        </p>
        <p class="form-control-paragraphs fs-15 ">Cuando sea necesario para nuestros intereses comerciales legítimos,
          como la prevención del fraude o para mantener la seguridad de nuestros usuarios.
        </p>
        <p class="form-control-paragraphs fs-22 ">Transferencias de datos</p>
        <p class="form-control-paragraphs fs-15 ">Su información personal puede almacenarse y procesarse en cualquier
          país donde tengamos instalaciones o donde contratemos proveedores de servicios. Al aceptar los términos de
          esta Política de privacidad, usted reconoce, acepta y acepta (1) la transferencia y el procesamiento de
          información personal en servidores ubicados fuera del país donde reside y (2) nuestra recopilación y uso de su
          información personal como descrito aquí y de acuerdo con las leyes de protección de datos de los Estados
          Unidos, que pueden ser diferentes y menos protectoras que las de su país. Si usted es residente del EEE o
          Suiza, tenga en cuenta que utilizamos cláusulas contractuales estándar aprobadas por la Comisión Europea para
          transferir su información personal del EEE o Suiza a los Estados Unidos y otros países.
        </p>
        <p class="form-control-paragraphs fs-22 ">Base legal para procesar su información personal (solo visitantes
          / clientes del EEE)</p>
        <p class="form-control-paragraphs fs-15 ">Si usted es un usuario ubicado en el EEE, nuestra base legal para
          recopilar y utilizar la información personal descrita anteriormente dependerá de la información personal en
          cuestión y del contexto específico en el que la recopilamos. Normalmente recopilaremos información personal de
          usted solo cuando tengamos su consentimiento para hacerlo, donde necesitemos la información personal para
          llevar a cabo un contrato con usted, o cuando el procesamiento sea para nuestros intereses comerciales
          legítimos. En algunos casos, también podemos tener la obligación legal de recopilar información personal sobre
          usted.
        </p>
        <p class="form-control-paragraphs fs-15 ">Si le pedimos que proporcione información personal para cumplir con un
          requisito legal o celebrar un contrato con usted, lo aclararemos en el momento pertinente y le informaremos si
          el suministro de su información personal es obligatorio o no (así como de las posibles consecuencias si no
          proporciona su información personal). Del mismo modo, si recopilamos y usamos su información personal en
          función de nuestros intereses comerciales legítimos, le dejaremos claro en el momento pertinente cuáles son
          esos intereses comerciales legítimos.
        </p>
        <p class="form-control-paragraphs fs-15 ">Si tiene preguntas o necesita más información sobre la base legal
          sobre la que recopilamos y usamos su información personal, comuníquese con nosotros utilizando los datos de
          contacto proporcionados en el encabezado "Cómo contactarnos" a continuación.</p>
        <p class="form-control-paragraphs fs-22 ">Acceso a la información</p>
        <p class="form-control-paragraphs fs-15 ">Para mantener sus Datos personales precisos, actuales y completos, contáctenos como se especifica a continuación. Tomaremos las medidas razonables para actualizar o corregir los Datos personales que haya enviado previamente a través de los Servicios.
        </p>
        <p class="form-control-paragraphs fs-22 ">Cómo contactarnos</p>
        <p class="form-control-paragraphs fs-15 ">No dude en contactarnos si tiene alguna pregunta sobre la Política
          de privacidad de Alto o las prácticas de información de los Servicios.</p>
        <p class="form-control-paragraphs fs-15 ">Puede contactarnos en <a
            href="https://supportalto.atlassian.net/servicedesk/customer/portal/4" target="_blank">https://supportalto.atlassian.net/servicedesk/customer/portal/4</a> o al correo <a
            href="mailto:dataprivacy@grupoalto.com">dataprivacy@grupoalto.com</a>.</p>
      </div>
    </div>
  </div>
</div>